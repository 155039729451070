.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.bssi-logo {
  height: var(--nav-content-height);
}

.bssi-logo:hover {
  cursor: pointer;
}

.left-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% / 4);
}
.mid-left-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% / 4);
}

.middle-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% / 4);
}

.middle-items div {
  font-size: 32px;
}

.right-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: middle;
  width: calc(100% / 4);
}

.right-items div {
  font-size: 16px;
  margin-top: 5px;
  margin-left: 20px;
  font-weight: normal;
}

.header-text {
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: dimgrey;
}

.auth-text:hover {
  cursor: pointer;
}

.link {
  color: #171f46;
  text-decoration: none;
}
